<script lang="ts" setup>
import { getNutrientValueFromOpenFoodItem } from '~/utils/openFoodFact';
import type { FoodOrigin, FoodStatus, FoodType } from '~/types/database';

const modalStore = useModalStore();
const { activeFood, createFood, updateFood } = useFoodStore();
const foodFamilyStore = useFoodFamilyStore();
const foodSubFamilyStore = useFoodSubFamilyStore();
const foodTagStore = useFoodTagStore();
const $api = useApi();

const { setValidation, required } = useValidation();
const {
  isLoading: isOpenFoodFactLoading,
  setLoadingPending,
  setLoadingSuccess,
  setLoadingFailed,
} = useLoading();

const optionsSelectFoodOrigin: { value: FoodOrigin; label: string }[] = [
  {
    value: 'nectar',
    label: 'Nectar',
  },
  {
    value: 'openfoodfact',
    label: 'Open Food Fact',
  },
  {
    value: 'usda',
    label: 'USDA',
  },
  {
    value: 'ciqual',
    label: 'Ciqual',
  },
];

const optionsSelectFoodStatus: { value: FoodStatus; label: string }[] = [
  {
    value: 'public',
    label: 'Public',
  },
  {
    value: 'hidden',
    label: 'Caché',
  },
  {
    value: 'review',
    label: 'À vérifier (non visible)',
  },
  {
    value: 'incomplete',
    label: 'Incomplet (non visible)',
  },
];
const searchFoodOpenFoodFactSelectId = ref();
const openFoodFactFoods = ref([]);

const searchFoodOpenFoodFactItem = computed(() =>
  openFoodFactFoods.value.find(
    ({ _id }) => _id === searchFoodOpenFoodFactSelectId.value,
  ),
);

const foodData = reactive({
  label: '',
  description: '',
  origin: null,
  originId: null,
  calories: 0,
  true_calories: 0,
  status: null,
  familyId: null,
  subfamilyId: null,
  tags: [],
  nutrients: [],
});

const isUpdateFood = computed(() => !!activeFood);

watch(
  () => searchFoodOpenFoodFactSelectId.value,
  (offFoodId) => {
    if (!isUpdateFood.value && offFoodId) {
      prefillFood(searchFoodOpenFoodFactItem.value);
    }
  },
);

function prefillFood(openfoodfood) {
  foodData.label = openfoodfood.product_name;
  foodData.description = openfoodfood.ingredients_text;
  foodData.calories = openfoodfood.nutriments['energy-kcal'];

  const nutrientToCheck = Object.keys(openfoodfood.nutriments).filter(
    (k) => !k.includes('_') && !k.includes('-'),
  );
  nutrientToCheck.forEach((nutrientKey) => {
    const originalNutrientKey = nutrientKey;

    const itemToUpdate = foodData.nutrients.find((n) => {
      if (nutrientKey[nutrientKey.length - 1] === 's') {
        nutrientKey = nutrientKey.slice(0, -1);
      }
      return n.label.toLowerCase().includes(nutrientKey);
    });
    if (itemToUpdate) {
      itemToUpdate.quantity = parseFloat(
        openfoodfood.nutriments[originalNutrientKey],
      );
    }
  });
}

watch(
  () => activeFood,
  (updateFood) => {
    if (!updateFood) {
      return;
    }

    const {
      label,
      description,
      origin,
      originId,
      calories,
      true_calories,
      status,
      family,
      subfamily,
      nutrients,
      attributes,
    } = updateFood;

    foodData.label = label;
    foodData.description = description;
    foodData.origin = origin;
    foodData.originId = originId;
    foodData.status = status;
    foodData.calories = calories;
    foodData.true_calories = true_calories;
    foodData.familyId = family?.id;
    foodData.subfamilyId = subfamily?.id;
    foodData.nutrients = nutrients.map((fN) => ({
      id: fN.id,
      label: fN.nutrient?.label,
      quantity: parseFloat(fN.quantity),
      unit: fN.nutrient.unit?.label,
      type: fN.nutrient.type,
      nutrientId: fN.nutrient.id,
    }));

    foodData.tags = Object.keys(attributes).reduce((acc, key) => {
      if (attributes[key]) {
        acc.push(key);
      }
      return acc;
    }, []);
  },
  { immediate: true },
);

const validation = setValidation(foodData, {
  label: { required },
  description: { required },
  familyId: { required },
});

async function handleCreateFood() {
  if (isUpdateFood.value) {
    await updateFood(activeFood.id, foodData);
  } else {
    await createFood(foodData);
  }
  await modalStore.closeModal();
}

foodFamilyStore.fetchAllFamilies();
foodSubFamilyStore.fetchAllSubFamilies();

async function fetchNectarNutrients() {
  const nutrients = await $api.post(`/nutrient/all`, {
    is_used_by_nectar: true,
  });
  foodData.nutrients = nutrients.data.map((n) => ({
    label: n.label,
    quantity: 0,
    unit: n.unit.label,
    type: n.type,
    nutrientId: n.id,
  }));
}

if (!isUpdateFood.value) {
  fetchNectarNutrients();
}

async function fetchFoodFromOpenFoodFact(search: String) {
  try {
    setLoadingPending();
    const { data } = await $api.post('/openfoodfacts', {
      search,
    });

    openFoodFactFoods.value = data;

    setLoadingSuccess();
  } catch (e) {
    setLoadingFailed();
  }
}

function handleUpdateFoodNutrient(nutrientRow, { value, unit }) {
  let multCoef = 1;
  if (nutrientRow.unit.symbol === 'MG' && unit === 'g') {
    multCoef = 1000;
  } else if (nutrientRow.unit.symbol === 'UG' && unit === 'g') {
    multCoef = 1 ^ 6;
  }
  nutrientRow.quantity = value * multCoef;
}
</script>
<template>
  <BaseModal
    :title="$t(`modal.foodUpsert.${isUpdateFood ? 'update' : 'create'}.title`)"
    :width="900"
  >
    <BaseSelect
      v-model="searchFoodOpenFoodFactSelectId"
      placeholder="Nutella, Beurre doux monoprix, Saumon écossais en tranche,.."
      type="text"
      label="Recherche OpenFoodFact"
      :options="
        openFoodFactFoods.map((f) => ({
          label: f.product_name,
          value: f._id,
        }))
      "
      :remote-call="fetchFoodFromOpenFoodFact"
      :is-loading="isOpenFoodFactLoading"
      clearable
      debounce
    />
    <hr class="my-4" />
    <form>
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.description.$model"
        label="Description"
        type="text"
        :validation="validation.description"
        required
      />
      <BaseSelect
        v-model="foodData.origin"
        label="Origin"
        :options="optionsSelectFoodOrigin"
        required
      />
      <BaseSelect
        v-model="foodData.status"
        label="Status"
        :options="optionsSelectFoodStatus"
        required
      />

      <BaseInput v-model="foodData.calories" label="Calories" type="number" />

      <BaseInput
        v-model="foodData.true_calories"
        label="True colories"
        type="number"
      />

      <BaseSelect
        v-model="validation.familyId.$model"
        label="Famille"
        :options="
          foodFamilyStore.allFamilies.map((f) => ({
            label: f.label,
            value: f.id,
          }))
        "
        :validation="validation.familyId"
        required
      />
      <BaseSelect
        v-model="foodData.subfamilyId"
        label="Sous-Famille"
        :options="
          foodSubFamilyStore.allSubFamilies.map((f) => ({
            label: f.label,
            value: f.id,
          }))
        "
      />

      <BaseSelect
        v-model="foodData.tags"
        label="Tags"
        :options="
          foodTagStore.ALL_TAGS.map((t) => ({
            label: t,
            value: t,
          }))
        "
        multiple
      />

      <h4>Nutriments associés</h4>
      <BaseDatagrid
        :entries="foodData.nutrients"
        :columns="[
          {
            label: 'Type',
            key: 'type',
            sortable: true,
          },
          {
            label: 'Label',
            key: 'label',
            sortable: true,
          },
          {
            label: 'Quantité',
            key: 'quantity',
            sortable: true,
          },
          {
            label: 'Unité',
            key: 'unit',
            sortable: true,
          },
          {
            label: 'OpenFoodFact',
            key: 'openfoodfact',
          },
        ]"
      >
        <template #row-data-type="{ row }">
          <BaseTag
            :content="row.type"
            :type="row.type === 'micro' ? 'primary' : 'warning'"
          />
        </template>
        <template #row-data-label="{ row }">
          <span class="font-bold">{{ row.label }} </span>
        </template>
        <template #row-data-quantity="{ row }">
          <BaseInput v-model="row.quantity" type="number" />
        </template>
        <template #row-data-unit="{ row }">
          <span class="font-bold">{{ row.unit }} </span>
        </template>

        <template #row-data-openfoodfact="{ row }">
          <BaseTag
            v-if="
              getNutrientValueFromOpenFoodItem(
                searchFoodOpenFoodFactItem,
                row.label,
              )
            "
            @click="
              handleUpdateFoodNutrient(
                row,
                getNutrientValueFromOpenFoodItem(
                  searchFoodOpenFoodFactItem,
                  row.label,
                ),
              )
            "
          >
            {{
              getNutrientValueFromOpenFoodItem(
                searchFoodOpenFoodFactItem,
                row.label,
              ).value
            }}

            {{
              getNutrientValueFromOpenFoodItem(
                searchFoodOpenFoodFactItem,
                row.label,
              ).unit
            }}
          </BaseTag>
        </template>
      </BaseDatagrid>
    </form>

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleCreateFood" />
    </template>
  </BaseModal>
</template>
